import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderComponent from '../../../../Common/components/header';
import FooterComponent from '../../../../Common/components/footer';
import OwlCarousel, { render } from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import avatar1 from '../../../../../images/city_detail_banner.png';
import { APIService } from '../../../../../Config/actions/apiAction';
import BuyNow from '../../../../Common/components/buynow';
import { TokenService } from '../../../../../Config/actions/tokenAction';
import swal from 'sweetalert';
import MetaDecorator from '../../../../Common/components/metaDecorator';


class MyTour extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: '',
            wishlist: [],
            purchased: [],
            meta_info: {
                title: 'My Tour',
                meta_description: 'My Tour - HopOn India',
                meta_keyword: 'My Tour - HopOn India'
            }
        }
    }

    responsive = {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,
        }
    };

    componentDidMount() {
        this.props.dispatch(APIService('POST', '/tourlocation/purchasetourlist', '')).then((data) => {
            if (data.data.status === 'success') {
                this.setState({
                    data: data.data
                }, () => {
                    let wishlist = [];
                    let purchased = [];
                    if (this.state.data.fav_tours.length > 0) {
                        this.state.data.fav_tours.map((item) => {
                            wishlist.push(item);
                        })
                    }

                    if (this.state.data.fav_package_data.length > 0) {
                        this.state.data.fav_package_data.map((item) => {
                            wishlist.push(item)
                        })
                    }

                    if (this.state.data.data.length > 0) {
                        this.state.data.data.map((item) => {
                            purchased.push(item)
                        })
                    }

                    if (this.state.data.package_data.length > 0) {
                        this.state.data.package_data.map((item) => {
                            purchased.push(item)
                        })
                    }
                    this.setState({
                        wishlist: wishlist,
                        purchased: purchased
                    })
                })
            }
            else if (data.data.auth_failed === '1') {
                this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                    this.showToast('error', 'Invalid login. Please try again');
                    this.props.logoutUser();
                })
            }
            else {
                // console.log('error', data)
            }
        }).catch((e) => {
            // console.log(e)
        })
    }

    showToast = (type, message) => {
        swal({
            text: ' ',
            title: message,
            icon: type,
            timer: 2000,
            buttons: false,
        })
    }

    route = (item) => {
        let url = ''
        if (item.tour_id) {
            url = `/tour-detail/${item.tour_id}`
        }
        else {
            url = `/package-detail/${item.package_id}`
        }

        this.props.history.push(url);
    }

    render() {
        return (
            <Fragment>
                {/* Html goes here */}
                <MetaDecorator meta_info={this.state.meta_info} />
                <HeaderComponent />
                <BuyNow />
                {/* banner section starts here */}
                <div className="banner_section inner_banner">
                    <img src={avatar1} alt="Banner image" />
                    <div className="container">
                        <button className="btn btn-border-white inner_page_back" onClick={() => this.props.history.goBack()}> <FontAwesomeIcon icon="angle-left" /> Back</button>
                    </div>
                    {/* <h2 className="banner_title">
                        Your Tours
                    </h2> */}
                </div>
                {/* banner section ends here */}

                {/* walks section starts here */}
                {
                    this.state.wishlist && this.state.wishlist.length > 0 ? (
                        <section className="page_section" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="main_title ">Tours and packages liked</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center tour_section_slider ">
                                        <OwlCarousel className="owl-theme" loop={this.state.wishlist.length > 4 ? true : false} margin={20} nav dots navclass={'<FontAwesomeIcon icon="arrow-left" />', '<FontAwesomeIcon icon="arrow-right" />'} items={4}>
                                            {
                                                this.state.wishlist.map((item, index) => {
                                                    return (
                                                        <div key={index} className="item" onClick={() => this.route(item)} style={{ cursor: 'pointer' }}>
                                                            <img src={item.banner_img} alt="Tour Image" />
                                                            <p className="white_text" style={{ fontWeight: 'bold' }}>{item.tour_id ? item.tour_name : item.package_name}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </section>
                    ) : (
                        <div className="row">
                            <div className="col-12 text-center mt-3">
                                <h4>You didn't like any tours yet. <a href="/cities-and-tours" className="orange_btn btn-sm btn">Click here</a></h4>
                            </div>
                        </div>
                    )
                }
                {/* walks section ends here */}

                {/* packages section starts here */}
                {
                    this.state.purchased && this.state.purchased.length > 0 ? (
                        <section className="page_section" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="main_title ">Tours and packages bought</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center tour_section_slider ">
                                        <OwlCarousel className="owl-theme" loop={this.state.purchased && this.state.purchased.length > 4 ? true : false} margin={20} nav dots navclass={'<FontAwesomeIcon icon="arrow-left" />', '<FontAwesomeIcon icon="arrow-right" />'} items={4} responsive={this.responsive}>
                                            {
                                                this.state.purchased.map((item, index) => {
                                                    return (
                                                        <div key={index} className="item" onClick={() => this.route(item)} style={{ cursor: 'pointer' }}>
                                                            <img src={item.media_url || item.package_image} alt="Tour Image" />
                                                            <p className="white_text" style={{ fontWeight: 'bold' }}>{item.tour_id ? item.tour_name : item.package_name}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </section>
                    ) : null
                }
                {/* packages section ends here */}


                <FooterComponent />

            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(MyTour);