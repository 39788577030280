import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderComponent from '../../../../Common/components/header';
import FooterComponent from '../../../../Common/components/footer';
import OwlCarousel, { render } from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { APIService } from '../../../../../Config/actions/apiAction';
import BuyNow from '../../../../Common/components/buynow';
import { ToastContainer, toast } from 'react-toastify';
import { TokenService } from '../../../../../Config/actions/tokenAction';
import MetaDecorator from '../../../../Common/components/metaDecorator';
import { redirectToPWA } from '../../../../../utitls';

class PackageDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            package: '',
            saved: false,
            meta_info: {},
        }
    }

    responsive = {
        0: {
            items: 1,
        },
        300: {
            items: 2,
        },
        600: {
            items: 3,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,
        }
    };

    componentDidMount() {
        let fd = new FormData();
        fd.append('package_id', this.props.match.params.id);
        this.props.dispatch(APIService('POST', '/walk/packagedetails', fd)).then((res) => {
            if (res && res.data.status === 'success') {
                this.setState({
                    package: res.data.data,
                    saved: res.data.data.location_info.is_favourite === '1' ? true : false,
                    meta_info: (res.data.data.meta_info != null) ? res.data.data.meta_info : { title: 'HopOn India', meta_description: 'HopOn India', meta_keyword: 'HopOn India' },
                })
            }
            else if (res.data.auth_failed === '1') {
                this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                    this.showToast('error', 'Invalid login. Please try again');
                    this.props.logoutUser();
                })
            }
            else {
                this.props.history.goBack();
            }
        }).catch((e) => {
            // console.log(e)
        })
    }

    route = (url) => {
        this.props.history.push(url);
    }

    // Like/Unlike package functionlity
    likeUnlike = () => {
        let fd = new FormData();
        fd.append('product_id', this.state.package.location_info.package_id);
        fd.append('product_type', '2');
        fd.append('action', this.state.saved ? '1' : '0');
        this.props.dispatch(APIService('POST', '/user/like-unlike', fd)).then((data) => {
            if (data && data.data.status === 'success') {
                this.showToast('success', data.data.message)
            }
            else if (data.data.auth_failed === '1') {
                this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                    this.showToast('error', 'Invalid login. Please try again');
                    this.props.logoutUser();
                })
            }
            else {
                // console.log(data)
            }
        }).catch((err) => {
            // console.log(err)
        })
    }

    // Save package functionality 
    saveTour = () => {
        if (this.props.user.isAuthenticated) {
            this.setState((prevstate) => {
                return {
                    ...prevstate,
                    saved: !prevstate.saved
                }
            }, () => {
                this.likeUnlike();
            })
        }
        else {
            this.showToast('error', 'Invalid login. Please login again');
        }
    }

    // Toast notification
    showToast = (type, message) => {
        if (type === 'success') {
            toast.success(message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false
            })
        }
        else {
            toast.error(message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false
            })
        }
    }

    render() {
        return (
            <Fragment>
                {/* Html goes here */}
                <MetaDecorator meta_info={this.state.meta_info} />
                <HeaderComponent />
                <BuyNow />
                <ToastContainer />
                {/* banner section starts here */}
                <div className="banner_section inner_banner package_slider">
                    {
                        (this.state.package && this.state.package.package_info) ? (
                            <OwlCarousel className="owl-theme" loop={this.state.package.package_info.length > 4 ? true : false} margin={0} dots items={1}>
                                {/* {
                                    this.state.package.package_info.map((item, index) => {
                                        return ( */}
                                <div className="item">
                                    <img src={this.state.package.location_info.banner_img} alt="Tour Image" />
                                </div>
                                {/* )
                                    })
                                } */}

                            </OwlCarousel>
                        ) : null
                    }
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <button className="btn btn-border-white inner_page_back" onClick={() => this.props.history.goBack()}> <FontAwesomeIcon icon="angle-left" /> Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* banner section ends here */}

                {/* price section starts here */}
                <section className="page_section price_section_tour package_details d-none d-md-block">
                    <div className="container">
                        <div className="row tour_details_desc">
                            <div className="col-12 col-md-12 ">
                                <div className="price_box float-md-right">
                                    <div className="price_box_body" style={{ height: '100%' }}>
                                        <h2 className="price_text">{(this.state.package.location_info) ? '₹' + this.state.package.location_info.package_price : ''}</h2>
                                        <p>Starting Price per Adult</p>
                                        <div className="d-inline-block purchase_opt">
                                            {/* <FontAwesomeIcon onClick={this.saveTour} icon="heart" className="like_option" style={this.state.saved ? { color: 'red', border: '1px solid silver' } : { color: 'grey', border: '1px solid silver' }}></FontAwesomeIcon> */}
                                            {
                                                this.state.package.location_info ? (this.state.package.location_info.is_bought === '1') ? (
                                                    <button className="btn btn-sm orange_btn text-uppercase">Purchased</button>
                                                ) : (
                                                    <button className="btn btn-sm orange_btn text-uppercase" onClick={() => {
                                                        // this.route(`/package-detail/${this.state.package.location_info.package_id}/purchase`)
                                                        redirectToPWA('package', this.state.package.location_info.package_id)
                                                    } }>BUY NOW</button>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* price section ends here */}

                {/* tour details section starts */}
                <section className="page_section">
                    <div className="container">
                        <div className="row package_detail_section">
                            <div className="col-12 col-md-7">
                                <h2 className="">{this.state.package.location_info && this.state.package.location_info.package_name}</h2>
                                <h5 className="">Total Tours: <b>{this.state.package.walkincluded_info && this.state.package.walkincluded_info.length}</b></h5>
                                <p className=" mt_20"> {this.state.package.location_info && this.state.package.location_info.package_description} </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* mobile price section starts here */}
                <section className="page_section price_section_tour package_details d-md-none">
                    <div className="container">
                        <div className="row tour_details_desc">
                            <div className="col-12 col-md-12 ">
                                <div className="price_box float-md-right">
                                    <div className="price_box_body" style={{ height: '100%' }}>
                                        <h2 className="price_text">{(this.state.package.location_info) ? '₹' + this.state.package.location_info.package_price : ''}</h2>
                                        <p>Starting Price per Adult</p>
                                        <div className="d-inline-block purchase_opt">
                                            <FontAwesomeIcon onClick={this.saveTour} icon="heart" className="like_option" style={this.state.saved ? { color: 'red', border: '1px solid silver' } : { color: 'grey', border: '1px solid silver' }}></FontAwesomeIcon>
                                            {
                                                this.state.package.location_info ? (this.state.package.location_info.is_bought === '1') ? (
                                                    <button className="btn btn-sm orange_btn text-uppercase">Purchased</button>
                                                ) : (
                                                    <button className="btn btn-sm orange_btn text-uppercase" onClick={() => this.route(`/package-detail/${this.state.package.location_info.package_id}/purchase`)}>BUY NOW</button>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                    {/* <div className="price_box_footer">
                                        <p>Get a taste of what this has to offer</p>
                                        <div className="orange_link_text"><img src={musicImg} /> Hear a Short Snippet</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* mobile price section ends here */}

                {/* walks section starts here */}
                <section className="page_section pt-0" >
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="main_title "><b>What tours are included</b></h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center tour_section_slider ">
                                {
                                    (this.state.package.walkincluded_info && this.state.package.walkincluded_info) ? (
                                        <OwlCarousel className="owl-theme" loop={this.state.package.walkincluded_info.length > 4 ? true : false} margin={20} nav dots navclass={'<FontAwesomeIcon icon="arrow-left" />', '<FontAwesomeIcon icon="arrow-right" />'} items={4} responsive={this.responsive}>
                                            {
                                                this.state.package.walkincluded_info.map((item, index) => {
                                                    return (
                                                        <div className="item itemCityList" key={index} style={{ cursor: 'pointer' }} onClick={() => this.route('/tour-detail/' + item.tour_id)}>
                                                            {
                                                                item.banner_img === null ? (
                                                                    <div style={{ width: '100%', height: '100%', backgroundColor: 'silver' }}>
                                                                        <FontAwesomeIcon icon="images" style={{ fontSize: '30px', marginTop: '70px' }} />
                                                                    </div>
                                                                ) : (
                                                                    <img src={item.banner_img} alt="Tour Image" style={{ height: '100%' }} />
                                                                )
                                                            }
                                                            <p className="white_text" style={{ fontWeight: 'bold' }}>{item.tour_name}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </OwlCarousel>
                                    ) : null
                                }

                            </div>
                        </div>
                    </div>
                </section>
                {/* walks section ends here */}

                <FooterComponent />

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer,
        apiData: state.ApiReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageDetail);