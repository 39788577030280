import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';


function BuyNow(props) {

    const clicked = () => {
        props.history.push('/buy-tour')
    }

    return (
        <Fragment>
            {
                props.location.pathname.includes("buy-tour") ? (
                    null
                ) : <button onClick={clicked} style={{ color: 'white', backgroundColor: '#f47421', position: 'fixed', right: '-42px', top: '50%', transform: 'translateY(-50%)', zIndex: '99', border: '0px', paddingLeft: '25px', paddingRight: '25px', paddingTop: '5px', paddingBottom: '7px', fontWeight: '500', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)', transform: 'rotate(90deg)' }}>Buy Tour</button>
            }
        </Fragment>
    )
}

export default withRouter(BuyNow);