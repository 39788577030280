import React, { Fragment } from 'react';

// Font Awesome Component
import "./components/Common/modules/fontAwesome";
import 'react-toastify/dist/ReactToastify.css';
import LoaderComponent from './components/Common/components/loader';

import MyRoutes from './router/router'; 
import './App.css';

function App() {
  return (
    <Fragment>
      <LoaderComponent />
      <MyRoutes />
    </Fragment>
  );
}

export default App;
