import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderComponent from '../../../Common/components/header';
import FooterComponent from '../../../Common/components/footer';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import tour1 from '../../../../images/city_detail_banner.png';
import { TokenService } from '../../../../Config/actions/tokenAction';
import { APIService } from '../../../../Config/actions/apiAction';
import { ToastContainer, toast } from 'react-toastify';
import Alert from '@material-ui/lab/Alert';
import BuyNow from '../../../Common/components/buynow';
import swal from 'sweetalert';
import MetaDecorator from '../../../Common/components/metaDecorator';

class myProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            phone: '',
            errorMessage: '',
            error: {
                email: '',
                name: '',
                phone: ''
            },
            meta_info: { title: 'My Profile', meta_description: 'My Profile - HopOn India', meta_keyword: 'My Profile - HopOn India' }
        }
    }

    inputHandler = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    componentDidMount() {
        this.props.dispatch(TokenService('getToken', 'hoi_user')).then((data) => {
            this.setState({
                email: data.email,
                name: data.name,
                phone: data.phone
            })
        })
    }

    // Showing swal alert
    showToast = (type, message) => {
        swal({
            text: ' ',
            title: message,
            icon: type,
            timer: 2000,
            buttons: false,
        })
    }

    // Update profile submit handler
    submitHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            errorMessage: ''
        }, () => {
            if ((this.state.email && this.state.name && this.state.phone) && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email)) && this.state.phone.length >= 10) {
                this.setState({
                    ...this.state,
                    error: {
                        email: '',
                        name: '',
                        phone: ''
                    }
                }, () => {
                    let fd = new FormData();
                    fd.append('name', this.state.name);
                    fd.append('email', this.state.email);
                    fd.append('phone', this.state.phone);
                    this.props.dispatch(APIService('POST', '/user/updateprofile', fd)).then((data) => {
                        if (data && data.data.status === 'success') {
                            this.props.dispatch(TokenService('getToken', 'hoi_user')).then((user) => {
                                let User = user;
                                User.phone = data.data.data.phone;
                                User.name = data.data.data.name;
                                this.props.dispatch(TokenService('setToken', { key: 'hoi_user', value: User })).then(() => {
                                    this.showToast('success', data.data.message);
                                })
                            })
                        }
                        else if (data.data.auth_failed === '1') {
                            this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                                this.showToast('error', 'Invalid login. Please try again');
                                this.props.logoutUser();
                            })
                        }
                        else {
                            this.setState({
                                errorMessage: data.data.message
                            })
                        }
                    }).catch(e => {
                        // console.log(e)
                    })
                })
            }
            else {
                this.setState({
                    ...this.state,
                    error: {
                        email: this.state.email ? (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email)) ? '' : 'Enter a valid email address' : 'Email address is required',
                        name: this.state.name ? '' : 'Name is required',
                        phone: this.state.phone ? (this.state.phone.length >= 10) ? '' : 'Phone number should be minimum 10 digits' : 'Phone number is required'
                    }
                })
            }
        })
    }

    render() {
        return (
            <Fragment>
                {/* Html goes here */}
                <MetaDecorator meta_info={this.state.meta_info} />
                <HeaderComponent />
                <BuyNow />
                {/* faqs section starts here */}
                <section className="page_section faq_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <form className="update_profile_form" onSubmit={this.submitHandler}>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="input-group mb-5">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><FontAwesomeIcon icon="user" /></span>
                                                </div>
                                                <input type="text" className="form-control" value={this.state.name} onChange={(e) => this.inputHandler('name', e.currentTarget.value)} placeholder="Enter Your Name" />
                                            </div>
                                            {this.state.error.name && <p style={{ color: 'red', fontSize: '14px', marginTop: '-42px', padding: '0' }}>{this.state.error.name}</p>}
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <div className="input-group mb-5">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><FontAwesomeIcon icon="envelope" /></span>
                                                </div>
                                                <input type="text" className="form-control" value={this.state.email} readOnly onChange={(e) => this.inputHandler('email', e.currentTarget.value)} placeholder="Enter Your Email" />
                                            </div>
                                            {this.state.error.email && <p style={{ color: 'red', fontSize: '14px', marginTop: '-42px', padding: '0' }}>{this.state.error.email}</p>}
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <div className="input-group mb-5">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><FontAwesomeIcon icon="mobile" /></span>
                                                </div>
                                                <input type="text" className="form-control" value={this.state.phone} onChange={(e) => this.inputHandler('phone', e.currentTarget.value)} placeholder="Phone number" />
                                            </div>
                                            {this.state.error.phone && <p style={{ color: 'red', fontSize: '14px', marginTop: '-42px', padding: '0' }}>{this.state.error.phone}</p>}
                                        </div>
                                        {
                                            this.state.errorMessage ? (
                                                <div className="col-12">
                                                    <Alert severity="error" style={{ marginBottom: '15px' }}>{this.state.errorMessage}</Alert>
                                                </div>
                                            ) : ''
                                        }
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input type="submit" className="form-control btn btn-sm orange_btn text-uppercase" value="Update" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                {/* faqs section ends here */}

                <FooterComponent />

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer,
        apiData: state.ApiReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(myProfile);