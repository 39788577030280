import React, {Component, Fragment} from 'react';

class BlogPage extends Component{
    render(){
        return (
            <Fragment>
                {/* Html goes here */}
                <div>
                    Blog Page works
                </div>
                
            </Fragment>
        )
    }
}

export default BlogPage;