import React from 'react';
import {connect} from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ user, component:Component, ...rest}) => {
        return (
            <Route {...rest} render={
                (props) => {
                    if(user.isAuthenticated){
                        return <Component {...props} />
                    }
                    else{
                        return <Redirect to='/login' />
                    }
                }
            } />
        ) 
}

const mapStateToProps = (state) => {
    return {
      user: state.UserReducer
    }
}

export default connect(mapStateToProps)(PrivateRoute);