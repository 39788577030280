import React, {Fragment, Component} from 'react';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { withRouter } from 'react-router-dom';
import DefaultImg from '../../../../../images/default_large.png';

class GridPage extends Component{
    
    constructor(props){
        super(props)
    }
    
    shouldComponentUpdate(newProps){
        if(this.props.filterData === newProps.filterData){
            return false;
        }

        return true;
    }

    route = (url) => {
        this.props.history.push(url)
    }

    render(){
        return (
            <Fragment>
                {
                    this.props.filterData && this.props.filterData.length > 0 ? (
                        <ResponsiveMasonry columnsCountBreakPoints={{350: 2, 540:3, 720: 3, 900: 4}} >
                            <Masonry columnsCount={4} gutter="10px">
                                {/* Math.floor(Math.random() * (max - min) + min) */}
                                {
                                    this.props.filterData && this.props.filterData.map((item, i) => {
                                        let imageHeight = Math.floor(Math.random() * (380 - 220) + 220)
                                        return (
                                            <Fragment key={i}>
                                                <img src={item.media_url === null ? DefaultImg : item.media_url} onClick={() => this.route('/tour-detail/'+item.tour_id)} height={imageHeight+'px'} className="walks_images" style={{width: "100%",  display: "block", objectFit:'cover', cursor:'pointer'}} alt="" />
                                                <p className="image_title_package white_text" style={{textAlign:'left'}}>{item.tour_name}</p>
                                            </Fragment>
                                        )
                                        
                                    })
                                }
                            </Masonry>
                        </ResponsiveMasonry>
                    ) : (
                        <div style={{width:'100%', marginBottom:'20px', border:'1px solid silver', paddingBottom:'12px', paddingTop:'12px', color:'grey', fontFamily:'sans-serif', fontSize:'16px', letterSpacing:'0.5px'}}>
                            Tour not found
                        </div>
                    )
                }
            </Fragment>
        )
    }
}

export default withRouter(GridPage);