import { useState, useEffect } from 'react';

const useForm = (validate, submitForm) => {
    const [values, setValues] = useState({
        name: '',
        phone: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const [submit, setSubmit] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (submit) {
            if (errors.name || errors.phone || errors.email || errors.password || errors.confirmPassword) {
                setSubmit(false);
            }
            else {
                let fd = new FormData();
                fd.append('name', values.name);
                fd.append('phone', values.phone);
                fd.append('email', values.email);
                fd.append('password', values.password);
                fd.append('verify_password', values.confirmPassword);
                fd.append('language_id', '1');
                submitForm(fd);
            }
        }
    }, [errors]);

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.currentTarget.name]: e.currentTarget.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmit(true);
        setErrors(validate(values));
    }

    return { handleChange, values, handleSubmit, errors }
}

// const mapStateToProps = (state) => {
//     return {
//         user: state.UserReducer,
//         apiData: state.ApiReducer
//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         loginUser: () => {
//             dispatch({
//                 type: 'loginUser'
//             });
//         },
//         logoutUser: () => {
//             dispatch({
//                 type: 'logoutUser'
//             });
//         },
//         dispatch
//     }
// }

export default useForm;