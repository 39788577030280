import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import tech_icon1 from '../../../../images/tech/icon1.png';
import tech_icon2 from '../../../../images/tech/icon2.png';
import tech_icon3 from '../../../../images/tech/icon3.png';
import tech_icon4 from '../../../../images/tech/icon4.png';
import tech_icon5 from '../../../../images/tech/icon5.png';
import tech_icon6 from '../../../../images/tech/icon6.png';
import content_icon1 from '../../../../images/content/icon1.png';
import content_icon2 from '../../../../images/content/icon2.png';
import content_icon3 from '../../../../images/content/icon3.png';
import content_icon4 from '../../../../images/content/icon4.png';
import content_icon5 from '../../../../images/content/icon5.png';
import content_icon6 from '../../../../images/content/icon6.png';
import cms_icon1 from '../../../../images/CMS/icon1.png';
import cms_icon2 from '../../../../images/CMS/icon2.png';
import cms_icon3 from '../../../../images/CMS/icon3.png';
import cms_icon4 from '../../../../images/CMS/icon4.png';
import cms_icon5 from '../../../../images/CMS/icon5.png';
import cms_icon6 from '../../../../images/CMS/icon6.png';
import choose_icon_1 from '../../../../images/choose_icon_1.png';
import choose_icon_4 from '../../../../images/choose_icon_4.png';
import choose_icon_5 from '../../../../images/choose_icon_5.png';
import choose_icon_6 from '../../../../images/choose_icon_6.png';
import choose_icon_2 from '../../../../images/choose_icon_2.png';
import choose_icon_3 from '../../../../images/choose_icon_3.png';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        // <Box p={3}>
        <Typography component="span">{children}</Typography>
        // </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Tech" {...a11yProps(0)} />
          <Tab label="Content" {...a11yProps(1)} />
          <Tab label="CMS and Backend" {...a11yProps(2)} />
          <Tab label="USer Features" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className="row mt-5 text-center why_choose_section ">
          <div className="col-12 col-md-4 mb-5">
            <img src={tech_icon1} alt="" width="80px" className=" mb-3" />
            <p>Get your Android and iOS <br />Apps with most modern <br />backend and user features</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={tech_icon2} alt="" width="80px" className=" mb-3" />
            <p>Get your Progressive Web <br />App for those who don’t <br />want to download an app</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={tech_icon3} alt="" width="80px" className=" mb-3" />
            <p> Secure Cloud hosting is <br />our responsibility</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={tech_icon4} alt="" width="80px" className=" mb-3" />
            <p>We take care of Play and <br />App store hosting </p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={tech_icon5} alt="" width="80px" className=" mb-3" />
            <p>Bug fixes and trouble <br />shoot – day to day <br />maintenance -we will <br />take care of</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={tech_icon6} alt="" width="80px" className=" mb-3" />
            <p>We provide Regular tech <br />and user feature updates</p>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="row mt-5 text-center why_choose_section ">
          <div className="col-12 col-md-4 mb-5">
            <img src={content_icon1} alt="" width="80px" className=" mb-3" />
            <p>We are content experts- <br />From research to writing a <br />fine script- we can do all as <br />you like it</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={content_icon2} alt="" width="80px" className=" mb-3" />
            <p>We know what the visitors <br />like best- we make tours <br />that are user centric and <br />enhance their experience</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={content_icon3} alt="" width="80px" className=" mb-3" />
            <p> English, Hindi, Regional <br />Indian or International- we <br />curate tours in almost all <br />languages</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={content_icon4} alt="" width="80px" className=" mb-3" />
            <p>We can use your audio <br />files and make a tour too</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={content_icon5} alt="" width="80px" className=" mb-3" />
            <p>With your visual content <br />support, we can put <br />together a beautiful <br />multimedia tour</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={content_icon6} alt="" width="80px" className=" mb-3" />
            <p>We use professional <br />narrators and studios to <br />record</p>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="row mt-5 text-center why_choose_section ">
          <div className="col-12 col-md-4 mb-5">
            <img src={cms_icon1} alt="" width="80px" className=" mb-3" />
            <p>We provide you a <br />password protected CMS <br />and backend</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={cms_icon2} alt="" width="80px" className=" mb-3" />
            <p>Our dashboard is very <br />simple and user friendly</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={cms_icon3} alt="" width="80px" className=" mb-3" />
            <p> You can easily change, <br />update or shift the points <br />of interest</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={cms_icon4} alt="" width="80px" className=" mb-3" />
            <p>User data allows better <br />governance and <br />policymaking</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={cms_icon5} alt="" width="80px" className=" mb-3" />
            <p>It’s a transparent digital <br />backend that gives your <br />team confidence in day to <br />day management</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={cms_icon6} alt="" width="80px" className=" mb-3" />
            <p>We train your teams to <br />handle the backend </p>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className="row mt-5 text-center why_choose_section ">
          <div className="col-12 col-md-4 mb-5">
            <img src={choose_icon_6} alt="" width="80px" className=" mb-3" />
            <p>Access tours with or <br />without app download on <br />their smart phone</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={choose_icon_2} alt="" width="80px" className=" mb-3" />
            <p>Simple to use, like audio <br />devices in museums, but <br />on visitors phone</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={choose_icon_3} alt="" width="80px" className=" mb-3" />
            <p>Scan Qr code to download <br />or listen </p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={choose_icon_4} alt="" width="80px" className=" mb-3" />
            <p>DIY Navigational tours <br />need no guide</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={choose_icon_5} alt="" width="50px" className=" mb-3" />
            <p>Well crafted tours in <br />beautiful storytelling <br />narrative and simple to <br />understand language</p>
          </div>
          <div className="col-12 col-md-4 mb-5">
            <img src={choose_icon_1} alt="" width="80px" className=" mb-3" />
            <p>Available with or without <br />internet/ wifi signals after <br />download</p>
          </div>
        </div>
      </TabPanel>
    </div>
  );
}
