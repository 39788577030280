import React, { lazy } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import ScrollToTop from './scrollToTop';

import HomePage from '../components/Pages/Main/Home/index';
import BlogPage from '../components/Pages/Main/Blog/index';
import LoginPage from '../components/Pages/Public/Login/index';
import ForgotPasswordPage from '../components/Pages/Public/Forgot-Password/index';
import ResetPasswordPage from '../components/Pages/Public/Reset-Password/index';
import RegisterPage from '../components/Pages/Public/Register/index';
import About from '../components/Pages/Main/aboutus/index';
import Partner from '../components/Pages/Main/partner/index';
import CityList from '../components/Pages/Main/city/city_list/index';
import TourList from '../components/Pages/Main/tours/tour-list/index';
import TourDetail from '../components/Pages/Main/tours/tour-detail/index';
import TourFree from '../components/Pages/Main/tours/tour-free/index';
import MyTour from '../components/Pages/Main/tours/my-tour/index';
import PackageDetail from '../components/Pages/Main/packages/package-detail/index';
import PurchasePage from '../components/Pages/Main/purchase/index';
import faqs from '../components/Pages/Main/faqs/index';
import buyAllTours from '../components/Pages/Main/buy-tour/index';
import myProfile from '../components/Pages/Main/my-profile/index';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import SuccessPage from "../components/Pages/Main/Success/success";
import PackageSuccessPage from "../components/Pages/Main/Success/packageSuccess";
import FailurePage from "../components/Pages/Main/Failure/failure";
import PackageFailurePage from "../components/Pages/Main/Failure/failurePackage";
import PagenotFound from '../components/Pages/Public/not-found/index';
import PhonepePackageSuccessPage from '../components/Pages/Public/phonepe/success/packageSuccess';
import PhonepeSuccessPage from '../components/Pages/Public/phonepe/success/success';
import PhonepeFailurePage from '../components/Pages/Public/phonepe/failure/failure';
import PhonepePackageFailurePage from '../components/Pages/Public/phonepe/failure/failurePackage';
import AppPage from "../components/Pages/Public/app-pages/index";

class MyRoutes extends React.Component {

  render() {
    // const HomePage = lazy(() => import('../components/Pages/Main/Home/index'));

    return (
      <div>
        <Router>
          <ScrollToTop />
          <Switch>
            {/* <Redirect exact path="/" to='/' /> */}
            <Route exact path="/" component={Partner} />
            <Route path="/cities-and-tours" exact component={HomePage} />
            <Route path="/cities-and-tours/:subject" component={HomePage} />

            {/* faq, about us privacy, term conditions */}
            <Route path="/app-pages/:id/:subject" component={AppPage} />

            {/* <PrivateRoute path="/tour/success/:token" component={SuccessPage} />
            <PrivateRoute path="/tour/failure/:token" component={FailurePage} />

            <PrivateRoute path="/package/success/:token" component={PackageSuccessPage} />
            <PrivateRoute path="/package/failure/:token" component={PackageFailurePage} /> */}

            <Route path="/tour/success/:token" component={SuccessPage} />
            <Route path="/tour/failure/:token" component={FailurePage} />

            <Route path="/package/success/:token" component={PackageSuccessPage} />
            <Route path="/package/failure/:token" component={PackageFailurePage} />

            <Route path="/tour/phonepe-success/:token" component={PhonepeSuccessPage} />
            <Route path="/tour/phonepe-failure/:token" component={PhonepeFailurePage} />
            <Route path="/package/phonepe-success/:token" component={PhonepePackageSuccessPage} />
            <Route path="/package/phonepe-failure/:token" component={PhonepePackageFailurePage} />

            {/* <Route path="/blog" component={BlogPage} /> */}
            <Route path="/about-us" component={About} />
            <Route exact path="/city-list/:city" component={CityList} />
            <Route path="/tour-list" component={TourList} />
            <Route exact path="/tour-detail/:id" component={TourDetail} />
            <Route exact path="/package-detail/:id" component={PackageDetail} />
            <Route path="/package-detail/:packageId/purchase" component={PurchasePage} />
            <Route path="/tour-detail/:tourId/purchase" component={PurchasePage} />
            <Route path="/tour-detail/:tourId/free" component={TourFree} />
            <Route path="/faqs" component={faqs} />
            <Route path="/buy-tour" component={buyAllTours} />
            <PublicRoute exact path="/reset-password/:token" component={ResetPasswordPage} />
            <PublicRoute path="/forgot-password" component={ForgotPasswordPage} />
            <PublicRoute path="/register" component={RegisterPage} />
            <PublicRoute path="/login" component={LoginPage} />
            <Route path="/not-found" component={PagenotFound} />
            <PrivateRoute path="/my-tour" component={MyTour} />
            <PrivateRoute path="/my-profile" component={myProfile} />
            <Redirect exact from='*' to='/' />
          </Switch>
        </Router>
      </div>
    )
  }
}

export default MyRoutes;