import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


function SimpleAccordion(props) {
    const classes = useStyles();
    const route = (url) => {
        sessionStorage.setItem('homeScroll', window.pageYOffset);
        props.history.push(url)
    }

    return (
        <div className="col-12">
            <div className={classes.root}>
                {
                    props.package_info.map((item, index) => {
                        return (
                            <Accordion>
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                                    {/* <Typography className={classes.heading}> */}
                                    <img src={item.banner_img} alt="Tour Image" width="100%" height="100%" className="acc_Tour_img" />
                                    <h4 className="text-uppercase white_text accordian_package_title">{item.package_name} <FontAwesomeIcon icon="angle-down" />
                                        <small className="walk_text d-none text-right"> {item.walks.length} Packages </small>
                                    </h4>
                                    {/* </Typography> */}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* <Typography> */}
                                    <div className="package_right_detail">
                                        <p>An anoucement of the best that Mumbai has to offer, from a Tour among the Bollywood Stars, the Gateway Of India and the colorful Dadar Parsi Colony. Get a taste of the multituders of the city near sleeps. </p>
                                        <div className="text-center">
                                            <button className="btn btn-sm orange_btn" onClick={() => route('/package-detail/' + item.package_id)}>Explore <FontAwesomeIcon icon="angle-right" /></button>
                                        </div>
                                    </div>
                                    {/* </Typography> */}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }
            </div>
        </div>
    );
}
export default withRouter(SimpleAccordion);