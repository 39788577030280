import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderComponent from '../../../../Common/components/header';
import FooterComponent from '../../../../Common/components/footer';
import OwlCarousel, { render } from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import avatar1 from '../../../../../images/city_detail_banner.png';
import DefaultImage from '../../../../../images/default_large.png';
import { APIService } from '../../../../../Config/actions/apiAction';
import BuyNow from '../../../../Common/components/buynow';
import { TokenService } from '../../../../../Config/actions/tokenAction';
import swal from 'sweetalert';
import MetaDecorator from '../../../../Common/components/metaDecorator';

class CityList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            city: '',
            data: '',
            meta_info: {}
        }

    }

    responsive = {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,
        }
    };

    componentDidMount() {
        this.setState({
            city: this.props.match.params.city
        }, () => {
            let fd = new FormData();
            fd.append('name', this.state.city.toLowerCase())
            this.props.dispatch(APIService('POST', '/city/citydetails', fd)).then((res) => {
                if (res && res.data.status === 'success') {
                    this.setState({
                        data: res.data.data,
                        meta_info: (res.data.data.meta_info != null) ? res.data.data.meta_info : { title: res.data.data.city_name, meta_description: res.data.data.city_name, meta_keyword: res.data.data.city_name },
                    }, () => {
                    })
                }
                else if (res.data.auth_failed === '1') {
                    this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                        this.showToast('error', 'Invalid login. Please try again');
                        this.props.logoutUser();
                    })
                }
                else {
                    // console.log(res)
                }
            }).catch((e) => {
                // console.log(e)
            })
        })
    }

    route = (url) => {
        this.props.history.push(url)
    }

    // Toast notification
    showToast = (type, message) => {
        swal({
            title: message,
            text: ' ',
            icon: type,
            timer: 2000,
            buttons: false,
        })
    }

    // Go back to previous page
    goBack = () => {
        let back = this.props.location.state.prevPath || '';
        if (back === 'home') {
            this.props.history.push('/')
        }
        else {
            this.props.history.goBack();
        }
    }

    render() {
        return (
            <Fragment>
                {/* Html goes here */}
                <MetaDecorator meta_info={this.state.meta_info} />
                <HeaderComponent />
                <BuyNow />
                {/* banner section starts here */}
                <div className="banner_section inner_banner">
                    <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'black', zIndex: '9', opacity: '0.4' }}>

                    </div>
                    {
                        (this.state.data && this.state.data.city_image) ? (
                            <img src={this.state.data.city_image} alt="Banner image" />
                        ) : null
                    }
                    <div className="container">
                        <button className="btn btn-border-white inner_page_back" style={{ zIndex: '99' }} onClick={() => this.props.history.goBack()}> <FontAwesomeIcon icon="angle-left" /> Back</button>
                    </div>
                    <h2 className="banner_title" style={{ zIndex: '99' }}>
                        {this.state.data.city_name}
                    </h2>
                </div>
                {/* banner section ends here */}

                {/* walks section starts here */}
                <section className="page_section" >
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="main_title ">Browse The Tours We Offer</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center tour_section_slider ">
                                {
                                    (this.state.data.walk_info && this.state.data.walk_info.length > 0) ? (
                                        <Fragment>
                                            <OwlCarousel className="owl-theme" loop={(this.state.data.walk_info && this.state.data.walk_info.length > 4) ? true : false} margin={20} nav dots navclass={'<FontAwesomeIcon icon="arrow-left" />', '<FontAwesomeIcon icon="arrow-right" />'} items={4} responsive={this.responsive}>
                                                {
                                                    this.state.data.walk_info.map((item, index) => {
                                                        return (
                                                            <div className="item itemCityList" key={index} style={{ cursor: 'pointer' }} onClick={() => this.route('/tour-detail/' + item.tour_id)}>
                                                                {
                                                                    item.banner_img ? (
                                                                        <img src={item.banner_img ? item.banner_img : DefaultImage} style={{ height: '172px' }} alt="Tour Image" />
                                                                    ) : (
                                                                        <div style={{ width: '100%', height: '100%', backgroundColor: 'silver' }}>
                                                                            <FontAwesomeIcon icon="images" style={{ fontSize: '30px', marginTop: '70px' }} />
                                                                        </div>
                                                                    )
                                                                }
                                                                <p className="white_text" style={{ fontWeight: 'bold' }}>{item.tour_name}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </OwlCarousel>
                                        </Fragment>
                                    ) : (
                                        <h4 className="text-center">No tours are available</h4>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {/* walks section ends here */}

                {/* packages section starts here */}
                {
                    (this.state.data.package_info && this.state.data.package_info.length > 0) ? (
                        <section className="page_section pt-0" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="main_title ">Browse The Packages We Offer</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center tour_section_slider ">

                                        {/*    (this.state.data.package_info && this.state.data.package_info.length > 0) ? (*/}
                                        <Fragment>
                                            <OwlCarousel className="owl-theme" loop={(this.state.data.package_info && this.state.data.package_info.length > 4) ? true : false} margin={20} nav dots navclass={'<FontAwesomeIcon icon="arrow-left" />', '<FontAwesomeIcon icon="arrow-right" />'} items={4} responsive={this.responsive} >
                                                {
                                                    this.state.data.package_info.map((item, index) => {
                                                        return (
                                                            <div className="item itemCityList" key={index} style={{ cursor: 'pointer' }} onClick={() => this.route('/package-detail/' + item.package_id)}>
                                                                <img src={item.banner_img ? item.banner_img : DefaultImage} style={item.banner_img ? {} : { objectFit: 'fill' }} alt="Tour Image" />
                                                                <p className="white_text" style={{ fontWeight: 'bold' }}>{item.package_name}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </OwlCarousel>
                                        </Fragment>

                                    </div>
                                </div>
                            </div>
                        </section>
                    ) : null
                }
                {/* packages section ends here */}


                <FooterComponent />

            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(CityList);
