export const TokenService = (type, payload) => {
    return async () => {
        if(type === 'setToken'){
            localStorage.setItem(payload.key, JSON.stringify(payload.value));
            return null;
        }
        else if(type === 'getToken'){
            if(localStorage.getItem(payload)){
                return JSON.parse(localStorage.getItem(payload));
            }
        }
        else if(type === 'removeToken'){
            localStorage.removeItem(payload);
            return null;
        }
    }
}