import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FooterComponent from '../../../Common/components/footer';
import beforeImg from "../../../../images/bg_shadow.svg";
import logo from "../../../../images/logo.png";
import PhoneCase from '../../../../images/phone_case.png';
import PhoneImg from '../../../../images/phn_img.png';
import AppImg from '../../../../images/mobile_app.png';
import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { APIService } from '../../../../Config/actions/apiAction';
import { TokenService } from '../../../../Config/actions/tokenAction';
import bgVideo from '../../../../images/bgVideo.mp4';
import swal from 'sweetalert';
import MetaDecorator from "../../../Common/components/metaDecorator";

class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emailAddress: '',
            password: '',
            errorMessage: '',
            error: {
                email: '',
                password: ''
            },
            meta_info: {
                title: 'Login',
                meta_description: 'Login - HopOn India',
                meta_keyword: 'Login - HopOn India',
            },
        }
    }

    componentDidMount() {
    }

    goHome = () => {
        this.props.history.goBack();
    }

    inputHandler = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    submitHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            errorMessage: ''
        }, () => {
            if ((this.state.emailAddress && this.state.password) && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.emailAddress)) {
                this.setState({
                    ...this.state,
                    error: {
                        email: '',
                        password: ''
                    }
                }, () => {
                    let fd = new FormData();
                    fd.append('email', this.state.emailAddress);
                    fd.append('password', this.state.password);
                    this.props.dispatch(APIService('POST', '/login/makelogin', fd)).then((data) => {
                        if (data && data.data.status === 'success') {
                            this.props.dispatch(TokenService('setToken', { key: 'hoi_user', value: data.data.data })).then(() => {
                                this.props.loginUser();
                                this.showToast('success', 'Login successful');
                                this.props.history.push('/');
                            })
                        }
                        else if (data.data.auth_failed === '1') {
                            this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                                this.showToast('error', 'Invalid login. Please try again');
                                this.props.logoutUser();
                            })
                        }
                        else {
                            this.setState({
                                errorMessage: 'Invalid credentials. Please try again'
                            })
                        }
                    }).catch((e) => {
                        // console.log(e)
                    })
                })
            }
            else {
                this.setState({
                    ...this.state,
                    error: {
                        email: this.state.emailAddress ? (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.emailAddress)) ? '' : 'Enter a valid email address' : 'Email address is required',
                        password: this.state.password ? '' : 'Password is required'
                    }
                })
            }
        })
    }

    showToast = (type, message) => {
        swal({
            text: ' ',
            title: message,
            icon: type,
            timer: 2000,
            buttons: false,
        })
    }

    render() {
        return (
            <Fragment>
                <MetaDecorator meta_info={this.state.meta_info} />
                {/* header starts here */}
                <header className="logo_header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 text-center">
                                <Link to="/cities-and-tours"><img width={100} src={logo} alt="Logo" /></Link>
                                {/* <a href="/cities-and-tours"><img width={100} src={logo} alt="Logo" /></a> */}
                            </div>
                        </div>
                    </div>
                </header>
                {/* header ends here */}

                {/* section starts here */}
                <section className="page_section login_section">
                    <video src={bgVideo} autoPlay muted loop className="videoBack"></video>
                    <img src={beforeImg} className="shadow_img" />
                    <div className="container-fluid">
                        <div className="row align-items-center mb-4">
                            <div className="col-12 back_page_option mb_30">
                                <button className="btn btn-border-white" onClick={this.goHome}> <FontAwesomeIcon icon="angle-left" /> Back</button>
                            </div>
                            <div className="col-12 col-md-6 order-1 order-md-2">
                                <div className="box_auth">
                                    <h3 className="white_text texxt-left mb-4">Log In</h3>
                                    {/* login form starts here */}
                                    <form className="mb_30" onSubmit={this.submitHandler}>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><FontAwesomeIcon icon="user" /></span>
                                            </div>
                                            <input type="text" className="form-control" autoComplete="off" value={this.state.emailAddress} onChange={(e) => this.inputHandler('emailAddress', e.currentTarget.value)} placeholder="Enter Your Email Address" />
                                        </div>
                                        {this.state.error.email && <p class="error_show">{this.state.error.email}</p>}

                                        <div className="input-group mb-3 ">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><FontAwesomeIcon icon="lock" /></span>
                                            </div>
                                            <input type="password" className="form-control" autoComplete="off" value={this.state.password} onChange={(e) => this.inputHandler('password', e.currentTarget.value)} placeholder="Enter Your password." />
                                        </div>
                                        {this.state.error.password && <p class="error_show">{this.state.error.password}</p>}
                                        {
                                            this.state.errorMessage ? (
                                                <Alert severity="error" style={{ marginBottom: '15px' }}>{this.state.errorMessage}</Alert>
                                            ) : ''
                                        }
                                        <div className="form-group">
                                            <input type="submit" className="form-control btn orange_btn btn-sm" value="LOG IN" />
                                        </div>
                                    </form>
                                    {/* login form ends here */}
                                    <p className="text-center"><Link to="/forgot-password" className="white_text"><b>Forgot your password?</b></Link></p>
                                    <p className="text-center"><Link to="/register" className="white_text"><b>New? Create a free account</b></Link></p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 text-center responsive_setdiv order-2 order-md-1">
                                <h3 className="white_text">Your Next <br /> Adventure Awaits</h3>
                                <img width={300} src={AppImg} alt="" className="phone_img" />
                                {/* <div className="image_section_set">
                                    <img width={350} src={PhoneCase} alt="" className="phone_img"/>
                                    <div className="set_image_box">
                                        <img width={227} src={PhoneImg} alt="" className="set_img"/>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* section ends here */}

                {/* footer starts here */}
                <FooterComponent />
                {/* footer ends here */}
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer,
        apiData: state.ApiReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);