import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderComponent from '../../../Common/components/header';
import FooterComponent from '../../../Common/components/footer';
import logo from "../../../../images/logo.png";
import { connect } from 'react-redux';
import errorIcon from '../../../../images/error_page.png';
import MetaDecorator from '../../../Common/components/metaDecorator';

class PagenotFound extends Component {


    componentDidMount() {
    }

    goHome = () => {
        this.props.history.goBack();
    }


    render() {
        return (
            <Fragment>
                <MetaDecorator meta_info={{ title: 'Page Not Found', meta_description: 'Page Not Found - HopOn India', meta_keyword: 'Page Not Found - HopOn India' }} />
                {/* header starts here */}
                <HeaderComponent />
                {/* header ends here */}

                {/* section starts here */}
                <section className="page_section not-found-section">
                    <div className="container mb-4">
                        <div className="row">
                            <div className="col-12 text-center">
                                <img src={errorIcon} width="120" className="mt-3 mb-4" />
                                <h1 className="error-title mb-4 mt-4">404 Page Not Found</h1>
                                <p className="mb-2">The above error occurred while the Web server was processing your request.</p>
                                <p>Please contact us if you think this is a server error. Thank you.</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section ends here */}

                {/* footer starts here */}
                <FooterComponent />
                {/* footer ends here */}
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer,
        apiData: state.ApiReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PagenotFound);