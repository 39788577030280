import React, { Fragment } from "react";
import { css } from "@emotion/core";
import MoonLoader from "react-spinners/MoonLoader";
import { connect } from "react-redux";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: pink;
  z-index: 9999999;
`;

class LoaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: false
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <Fragment>
                <div className="sweet-loading" style={{ position: 'fixed', zIndex: '999999999', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}>
                    <MoonLoader
                        size={80}
                        color={"#FF8548"}
                        loading={this.props.apiData.fetching}
                    />
                </div>
                {
                    this.props.apiData.fetching ? (
                        <div style={{ backgroundColor: 'black', height: '100vh', width: '100%', position: 'fixed', top: '0', zIndex: '99999999', opacity: '0.6', overflow: 'hidden' }}></div>
                    ) : null
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer,
        apiData: state.ApiReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoaderComponent);