let initState = {
    data: [],
    fetching: false
}

const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
const FETCH_DATA_COMPLETED = 'FETCH_DATA_COMPLETED';

export const fetchDataRequest = () => {
    return {
        type: FETCH_DATA_REQUEST
    }
}

export const fetchDataComplete = (data) => {
    return {
        type: FETCH_DATA_COMPLETED,
        payload: data
    }
}

const apiReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_DATA_REQUEST: {
            return Object.assign({
                ...state,
                fetching: true
            })
        }
        
        case FETCH_DATA_COMPLETED: {
            return Object.assign({
                ...state,
                fetching: false
            })
        }

        default: 
            break;
    }
    return state;
}

export default apiReducer;