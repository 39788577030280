import React, { Component, Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FooterComponent from '../../../Common/components/footer';
import beforeImg from "../../../../images/bg_shadow.svg";
import logo from "../../../../images/logo.png";
import AppImg from '../../../../images/mobile_app.png';
import PhoneCase from '../../../../images/phone_case.png';
import PhoneImg from '../../../../images/phn_img.png';
import { Link, withRouter } from 'react-router-dom';
import useForm from './useForm';
import validate from './validateInfo';
import { connect } from 'react-redux';
import { APIService } from '../../../../Config/actions/apiAction';
import { TokenService } from '../../../../Config/actions/tokenAction';
import Alert from '@material-ui/lab/Alert';
import bgVideo from '../../../../images/bgVideo.mp4';
import swal from 'sweetalert';
import MetaDecorator from '../../../Common/components/metaDecorator';

function RegisterPage(props) {

    const [errorMsg, setErrorMsg] = useState('');

    const goHome = () => {
        props.history.push('/');
    }

    const showToast = (type, message) => {
        swal({
            text: ' ',
            title: message,
            icon: type,
            timer: 2000,
            buttons: false,
        })
    }

    const submitForm = (fd) => {
        setErrorMsg('');
        props.dispatch(APIService('POST', '/user/register', fd)).then((data) => {
            if (data && data.data.status === 'success') {
                props.dispatch(TokenService('setToken', { key: 'hoi_user', value: data.data.data })).then(() => {
                    props.loginUser();
                    showToast('success', 'Registered successfully!');
                    props.history.push('/');
                })
            }
            else if (data.data.auth_failed === '1') {
                this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                    this.showToast('error', 'Invalid login. Please try again');
                    this.props.logoutUser();
                })
            }
            else {
                setErrorMsg(data.data.message)
            }
        }).catch((e) => {
            // console.log(e)
        })
    }

    const { handleChange, values, handleSubmit, errors } = useForm(validate, submitForm);

    return (
        <Fragment>
            <MetaDecorator meta_info={{ title: 'Register', meta_description: 'Register - HopOn India', meta_keyword: 'Register - HopOn India' }} />
            {/* header starts here */}
            <header className="logo_header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center">
                            <a href="/cities-and-tours"><img width={100} src={logo} alt="Logo" /></a>
                        </div>
                    </div>
                </div>
            </header>
            {/* header ends here */}

            {/* section starts here */}
            <section className="page_section login_section registration_Section">
                <video src={bgVideo} autoPlay muted loop className="videoBack"></video>
                <img src={beforeImg} className="shadow_img" />
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12 back_page_option mb_30">
                            <button className="btn btn-border-white" onClick={goHome}> <FontAwesomeIcon icon="angle-left" /> Back</button>
                        </div>
                        <div className="col-12 col-md-6 order-1 order-md-2">
                            <div className="box_auth login_section">
                                <h3 className="white_text mb_30 texxt-left mb-4">Create Account</h3>
                                {/* login form starts here */}
                                <form className="mb_30" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><FontAwesomeIcon icon="id-card" /></span>
                                                </div>

                                                <input type="text" className="form-control" name="name" value={values.name} onChange={handleChange} placeholder="Name" />
                                            </div>
                                            {errors.name && <p class="error_show">{errors.name}</p>}
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><FontAwesomeIcon icon="mobile-alt" /></span>
                                                </div>
                                                <input type="text" className="form-control" name="phone" value={values.phone} onChange={handleChange} placeholder="Phone Number" />
                                            </div>
                                            {errors.phone && <p class="error_show">{errors.phone}</p>}
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><FontAwesomeIcon icon="user" /></span>
                                                </div>
                                                <input type="text" className="form-control" name="email" value={values.email} onChange={handleChange} placeholder="Enter Your Email Address" />
                                            </div>
                                            {errors.email && <p class="error_show">{errors.email}</p>}
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><FontAwesomeIcon icon="lock" /></span>
                                                </div>
                                                <input type="password" className="form-control" name="password" value={values.password} onChange={handleChange} placeholder="Enter Your password." />
                                            </div>
                                            {errors.password && <p class="error_show">{errors.password}</p>}
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><FontAwesomeIcon icon="lock" /></span>
                                                </div>
                                                <input type="password" className="form-control" name="confirmPassword" value={values.confirmPassword} onChange={handleChange} placeholder="Re-enter Your password." />
                                            </div>
                                            {errors.confirmPassword && <p class="error_show">{errors.confirmPassword}</p>}
                                        </div>
                                        <div className="col-12">
                                            {
                                                errorMsg ? (
                                                    <Alert severity="error" style={{ marginBottom: '15px' }}>{errorMsg}</Alert>
                                                ) : ''
                                            }
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input type="submit" className="form-control btn orange_btn btn-sm text-uppercase" value="Create" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {/* login form ends here */}
                                <p className="text-center"><Link to="/login" className="white_text"><b>Already have an account? Log In</b></Link></p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 text-center responsive_setdiv order-1 order-md-1">
                            <h3 className="white_text text-capitalize">amazing <br />experiences await</h3>
                            <img width={300} src={AppImg} alt="" className="phone_img" />
                            {/* <div className="image_section_set">
                                <img width={350} src={PhoneCase} alt="" className="phone_img"/>
                                <div className="set_image_box">
                                    <img width={227} src={PhoneImg} alt="" className="set_img"/>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* section ends here */}

            {/* footer starts here */}
            <FooterComponent />
            {/* footer ends here */}
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer,
        apiData: state.ApiReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegisterPage));