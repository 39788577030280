import { library } from '@fortawesome/fontawesome-svg-core';
import { 
    faUser, 
    faEnvelope, 
    faLock,
    faAddressCard,
    faAddressBook,
    faAngleRight,
    faVolumeUp,
    faAngleLeft,
    faCheck,
    faAngleDown,
    faAngleUp,
    faArrowLeft,
    faArrowRight,
    faPen,
    faPause,
    faHeart,
    faMusic,
    faIdCard,
    faMobileAlt,
    faImages,
    faPhone,
    faMobile,
    faCreditCard,
    faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import {
    faInstagram,
    faFacebook,
    faTwitter,
    faLinkedin,
    faWhatsapp
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faMobileAlt,
    faIdCard,
    faUser, 
    faEnvelope, 
    faLock,
    faAddressCard,
    faAddressBook,
    faAngleRight,
    faVolumeUp,
    faAngleLeft,
    faCheck,
    faAngleDown,
    faAngleUp,
    faArrowLeft,
    faArrowRight,
    faInstagram,
    faPen,
    faPause,
    faFacebook,
    faTwitter,
    faLinkedin,
    faHeart,
    faMusic,
    faImages,
    faPhone,
    faMobile,
    faCreditCard,
    faCalendar,
    faWhatsapp
);
