import React from 'react';
import { withRouter } from 'react-router';
import { Helmet } from "react-helmet";

function MetaDecorator(props) {
    return (
        <Helmet>
            <title>{props.meta_info.title}</title>
            <meta name="title" content={props.meta_info.title} />
            <meta name="description" content={props.meta_info.meta_description} />
            <meta name="keyword" content={props.meta_info.meta_keyword} />
        </Helmet>
    );
}
export default withRouter(MetaDecorator);