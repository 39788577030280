import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

class MapComponent extends Component {
    constructor(props) {
        super(props)
    }

    shouldComponentUpdate(newProps) {
        if (newProps.tourData === this.props.tourData) {
            return false;
        }

        return true;
    }

    render() {
        return (
            <Map google={this.props.google} initialCenter={{ lat: this.props.tourData.poi_on_walk[0].point_latitude, lng: this.props.tourData.poi_on_walk[0].point_longitude }} zoom={15} style={{ width: '97%' }}>
                {
                    this.props.tourData.poi_on_walk.map((item, index) => {
                        return (
                            <Marker key={index}
                                name={item.point_name}
                                title={item.point_name}
                                position={{ lat: item.point_latitude, lng: item.point_longitude }} />
                        )
                    })
                }
            </Map>
        )
    }
}

export default (GoogleApiWrapper({
    apiKey: (process.env.REACT_APP_GOOGLE_API_KEY)
})(MapComponent));