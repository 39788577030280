import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FooterComponent from '../../../Common/components/footer';
import beforeImg from "../../../../images/bg_shadow.svg";
import logo from "../../../../images/logo.png";
import AppImg from '../../../../images/mobile_app.png';
import PhoneCase from '../../../../images/phone_case.png';
import PhoneImg from '../../../../images/phn_img.png';
import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { APIService } from '../../../../Config/actions/apiAction';
import { TokenService } from '../../../../Config/actions/tokenAction';
import bgVideo from '../../../../images/bgVideo.mp4';
import swal from 'sweetalert';
import MetaDecorator from '../../../Common/components/metaDecorator';

class ResetPasswordPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: '',
            password: '',
            confirmpassword: '',
            errorMessage: '',
            error: {
                password: '',
                confirmpassword: ''
            },
            meta_info: { title: 'Reset Password', meta_description: 'Reset Password - HopOn India', meta_keyword: 'Reset Password - HopOn India' }
        }
    }

    componentDidMount() {
        if (this.props.match.params.token) {
            let fd = new FormData();
            fd.append('reset_link', this.props.match.params.token);
            this.props.dispatch(APIService('POST', '/login/verify-reset-link', fd)).then((data) => {
                if (data && data.data.status === 'success') {
                    this.setState({
                        token: this.props.match.params.token
                    })
                }
                else if (data.data.auth_failed === '1') {
                    this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                        this.showToast('error', 'Invalid login. Please try again');
                        this.props.logoutUser();
                    })
                }
                else {
                    this.showToast('error', 'Reset link expired');
                    this.goBack();
                }
            }).catch((e) => {
                // console.log(e)
            })
        }
        else {
            this.goBack();
        }
    }

    goBack = () => {
        this.props.history.push('/login');
    }

    inputHandler = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    submitHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            errorMessage: ''
        }, () => {
            if ((this.state.password && this.state.confirmpassword) && (this.state.password === this.state.confirmpassword)) {
                this.setState({
                    ...this.state,
                    error: {
                        password: '',
                        confirmpassword: ''
                    }
                }, () => {
                    let fd = new FormData();
                    fd.append('new_password', this.state.password);
                    fd.append('confirm_password', this.state.confirmpassword);
                    fd.append('reset_link', this.state.token);
                    this.props.dispatch(APIService('POST', '/login/resetpassword', fd)).then((data) => {
                        if (data && data.data.status === 'success') {
                            this.showToast('success', 'Password updated successfully!');
                            this.props.history.push('/login');
                        }
                        else if (data.data.auth_failed === '1') {
                            this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                                this.showToast('error', 'Invalid login. Please try again');
                                this.props.logoutUser();
                            })
                        }
                        else {
                            this.setState({
                                errorMessage: data.data.message,
                                password: '',
                                confirmpassword: ''
                            })
                        }
                    }).catch((e) => {
                        // console.log(e)
                    })

                })
            }
            else {
                this.setState({
                    ...this.state,
                    error: {
                        password: this.state.password ? '' : 'Password is required',
                        confirmpassword: this.state.confirmpassword ? (this.state.password === this.state.confirmpassword) ? '' : "Confirm password doesn't match" : 'Confirm password is required'
                    }
                })
            }
        })
    }

    showToast = (type, message) => {
        swal({
            text: ' ',
            title: message,
            icon: type,
            timer: 2000,
            buttons: false,
        })
    }

    render() {
        return (
            <Fragment>
                <MetaDecorator meta_info={this.state.meta_info} />
                {/* header starts here */}
                <header className="logo_header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 text-center">
                                <a href="/cities-and-tours"><img width={100} src={logo} alt="Logo" /></a>
                            </div>
                        </div>
                    </div>
                </header>
                {/* header ends here */}

                {/* section starts here */}
                <section className="page_section reset_section login_section">
                    <video src={bgVideo} autoPlay muted loop className="videoBack"></video>
                    <img src={beforeImg} className="shadow_img" />
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-12 back_page_option mb_30">
                                <button className="btn btn-border-white" onClick={this.goBack}> <FontAwesomeIcon icon="angle-left" /> Back</button>
                            </div>
                            <div className="col-12 col-md-6 order-1 order-md-2">
                                <div className="box_auth">
                                    <h3 className="white_text texxt-left mb-4">Reset Password</h3>
                                    {/* login form starts here */}
                                    <form className="mb_30" onSubmit={this.submitHandler}>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><FontAwesomeIcon icon="user" /></span>
                                            </div>
                                            <input type="password" className="form-control" autoComplete="off" value={this.state.password} onChange={(e) => this.inputHandler('password', e.currentTarget.value)} placeholder="Enter Password" />
                                        </div>
                                        {this.state.error.password && <p class="error_show">{this.state.error.password}</p>}

                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><FontAwesomeIcon icon="user" /></span>
                                            </div>
                                            <input type="password" className="form-control" autoComplete="off" value={this.state.confirmpassword} onChange={(e) => this.inputHandler('confirmpassword', e.currentTarget.value)} placeholder="Enter Confirm Password" />
                                        </div>
                                        {this.state.error.confirmpassword && <p class="error_show">{this.state.error.confirmpassword}</p>}

                                        {
                                            this.state.errorMessage ? (
                                                <Alert severity="error" style={{ marginBottom: '15px' }}>{this.state.errorMessage}</Alert>
                                            ) : ''
                                        }
                                        <div className="form-group">
                                            <input type="submit" className="form-control btn orange_btn btn-sm" value="RESET PASSWORD" />
                                        </div>
                                    </form>
                                    {/* login form ends here */}
                                    <p className="text-center"><Link to="/login" className="white_text"><b>Back to Login</b></Link></p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 text-center responsive_setdiv order-2 order-md-1">
                                <h3 className="white_text">Your Next <br /> Adventure Awaits</h3>
                                <img width={300} src={AppImg} alt="" className="phone_img" />
                                {/* <div className="image_section_set">
                                    <img width={350} src={PhoneCase} alt="" className="phone_img"/>
                                    <div className="set_image_box">
                                        <img width={227} src={PhoneImg} alt="" className="set_img"/>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* section ends here */}

                {/* footer starts here */}
                <FooterComponent />
                {/* footer ends here */}
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer,
        apiData: state.ApiReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
