import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderComponent from '../../../../Common/components/header';
import FooterComponent from '../../../../Common/components/footer';

import { APIService } from '../../../../../Config/actions/apiAction';
import { Howl, Howler } from 'howler';
import { ToastContainer, toast } from 'react-toastify';
import BuyNow from '../../../../Common/components/buynow';
import { TokenService } from '../../../../../Config/actions/tokenAction';
import GPlay from '../../../../../images/gplay.png';
import Applestore from '../../../../../images/applestore.png';
import Mytours from '../../../../../images/tour_menu.png';
import MetaDecorator from '../../../../Common/components/metaDecorator';

class TourFree extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tourID: this.props.match.params.tourId,
            tourData: '',
            tour: '',
            saved: false,
            loading: false,
            play: false,
            player: '',
            show: true,
            meta_info: {},
        }
    }

    componentDidMount() {
        let fd = new FormData();
        fd.append('tour_id', this.state.tourID)
        this.props.dispatch(APIService('POST', '/walk/tourdetails', fd)).then((res) => {
            if (res && res.data.status === 'success') {
                this.setState({
                    saved: res.data.data.location_info.is_favourite === '1' ? true : false,
                    tourData: res.data.data,
                    tour: res.data.data.location_info,
                    meta_info: (res.data.data.meta_info != null) ? res.data.data.meta_info : { title: 'HopOn India', meta_description: 'HopOn India', meta_keyword: 'HopOn India' },
                })
            }
            else if (res.data.auth_failed === '1') {
                this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                    this.showToast('error', 'Invalid login. Please try again');
                    this.props.logoutUser();
                })
            }
        }).catch((e) => {
            // console.log(e)
        })

    }

    render() {
        return (
            <Fragment>
                {/* Html goes here */}
                <MetaDecorator meta_info={this.state.meta_info} />
                <HeaderComponent />

                {/* free components starts */}
                <section className="page_section">
                    <div className="container mt-4 mb-4">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2 class="main_title mb-2">Enjoy your free audio tour of</h2>
                                <h2 className="orange_text main_title">{this.state.tour.tour_name}</h2>

                                <div className="text-center mt-4 pt-2 download_option" >
                                    <h4>Download the App now!</h4>
                                    <a className="mr-3" href="https://play.google.com/store/apps/details?id=com.hoponindia&hl=en_IN&gl=US">
                                        <img src={GPlay} width="150" />
                                    </a>
                                    <a href="https://apps.apple.com/us/app/hopon-india/id1205385874">
                                        <img src={Applestore} width="150" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* how to use section :: start */}
                <section class="page_section  light-bg">
                    <div class="container mb-4 mt-4">
                        <div class="row align-items-center">
                            <div class="col-12 col-md-6 text-center mb-4 mb-mb-0">
                                <img src={Mytours} width="300" className="mytour_app_img" />
                            </div>
                            <div class="col-12 col-md-6">
                                <h2 class="main_title">To enjoy the tour</h2>
                                <ul className="list_why">
                                    <li><FontAwesomeIcon className="list_why_icon" icon="check"></FontAwesomeIcon> Please ensure that you have downloaded the app on the phone </li>
                                    <li><FontAwesomeIcon className="list_why_icon" icon="check"></FontAwesomeIcon> Please login to the app using your registered email id </li>
                                    <li><FontAwesomeIcon className="list_why_icon" icon="check"></FontAwesomeIcon> Go to "My Tours"</li>
                                    <li><FontAwesomeIcon className="list_why_icon" icon="check"></FontAwesomeIcon> Locate the tour - <span className="orange_text"><b>{this.state.tour.tour_name}</b></span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* how to use section :: end */}
                <FooterComponent />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer,
        apiData: state.ApiReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TourFree);