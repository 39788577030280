import React, {Fragment} from "react"
import { Component } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { Route, withRouter } from "react-router-dom";
import DefaultImg from '../../../images/default_large.png';

class PackageGrid extends Component {

    constructor(props){
        super(props)
    }
    // const route = (url) => {
    //     props.history.push(url, { prevPath: 'home' })
    // }

    shouldComponentUpdate(newProps){
        if(newProps.masonaryData === this.props.masonaryData){
            return false;
        }

        return true;
    }

    render(){
        return (
            <Fragment>
                {
                    this.props.masonaryData ? (
                        <ResponsiveMasonry columnsCountBreakPoints={{350: 2, 540:3, 720:3,  767: 3, 900: 4}} >
                            <Masonry columnsCount={4} gutter="10px">
                                {/* Math.floor(Math.random() * (max - min) + min) */}
                                {
                                    this.props.masonaryData && this.props.masonaryData.map((item, i) => {
                                        let imageHeight = Math.floor(Math.random() * (380 - 220) + 220)
                                        return (
                                            <Fragment key={i}>
                                                <img src={item.banner_img === null ? DefaultImg : item.banner_img} onClick={() => this.props.route('/tour-detail/'+item.tour_id)} height={imageHeight+'px'} className="walks_images" style={{width: "100%",  display: "block", objectFit:'cover', cursor:'pointer'}} alt="" />
                                                <p className="image_title_package white_text" style={{textAlign:'left'}}>{item.tour_name}</p>
                                            </Fragment>
                                        )
                                        
                                    })
                                }
                            </Masonry>
                        </ResponsiveMasonry>
                    ) : null
                }
            </Fragment>
        )
    }
}

export default withRouter(PackageGrid);
