export default function validateInfo(values) {
    let errors = {};

    if(!values.name){
        errors.name = "Name is required"
    }

    if(!values.phone){
        errors.phone = "Phone number is required"
    } else if(values.phone.length < 10){
        errors.phone = "Phone number should be minimum 10 characters"
    }

    if(!values.email){
        errors.email = "Email address is required"
    } else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)){
        errors.email = "Enter a valid email address"
    }

    if(!values.password){
        errors.password = "Password is required"
    } else if(values.password.length < 6){
        errors.password = "Password should be minimum 6 characters"
    }

    if(!values.confirmPassword){
        errors.confirmPassword = "Confirm password is required"
    } else if(values.password !== values.confirmPassword){
        errors.confirmPassword = "Password does not match"
    }

    return errors;
}