const initState = {
    isAuthenticated: localStorage.getItem('hoi_user') ? true : false
}

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case 'loginUser':
            state = {
                ...state,
                isAuthenticated: true
            }
            break;
        
        case 'logoutUser':
            state = {
                ...state,
                isAuthenticated: false
            }
            break;
        default:
            break;
    }
    return state;
}

export default userReducer;