import React, { Component } from 'react';
import { connect } from 'react-redux';
import { APIService } from '../../../../Config/actions/apiAction';
import swal from 'sweetalert';
import { Fragment } from 'react';

class AppPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page_content: ''
        }
    }

    route = (url) => {
        sessionStorage.setItem('homeScroll', window.pageYOffset);
        this.props.history.push(url)
    }

    showToast = (type, message) => {
        swal({
            text: ' ',
            title: message,
            icon: type,
            timer: 2000,
            buttons: false,
        })
    }

    componentDidMount() {
        // About us, Terms & Conditions, FAQ, Privacy Policy, Refund and cancellation policy
        let page_name;
        if (this.props.match.params.subject == 'faq') { page_name = 'FAQ' }
        if (this.props.match.params.subject == 'about-us') { page_name = 'About us' }
        if (this.props.match.params.subject == 'terms-and-conditions') { page_name = 'Terms & Conditions' }
        if (this.props.match.params.subject == 'privacy-policy') { page_name = 'Privacy Policy' }
        if (this.props.match.params.subject == 'refund-and-cancellation-policy') { page_name = 'Refund and cancellation policy' }
        let fd = new FormData();
        fd.append('page_name', page_name);
        fd.append('tenant_id', this.props.match.params.id);
        this.props.dispatch(APIService('POST', '/home/app-pages', fd)).then((data) => {
            if (data && data.data.status === 'success') {
                this.setState({
                    page_content: data.data.data.page_content
                })
            }
            else if (data && data.data.status === 'error') { this.route('/not-found') }
        }).catch((e) => {
            // console.log(e)
        })
    }

    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="app_pages col-12 mt-5 mb-3" dangerouslySetInnerHTML={{ __html: this.state.page_content }}>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(AppPage);