import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderComponent from '../../../Common/components/header';
import FooterComponent from '../../../Common/components/footer';
import avatar1 from '../../../../images/about_banner.png';
import about1 from '../../../../images/about_img.png';
import Recaptcha from 'react-recaptcha';
import { APIService } from '../../../../Config/actions/apiAction';
import BuyNow from '../../../Common/components/buynow';
import { TokenService } from '../../../../Config/actions/tokenAction';
import swal from 'sweetalert';
import logo from "../../../../images/logo.png";
import MetaDecorator from '../../../Common/components/metaDecorator';

let recaptchaInstance;

class About extends Component {

    constructor(props) {
        super(props);
        this.state = {
            meta_info: {
                title: 'About us',
                meta_description: 'About us - HopOn India',
                meta_keyword: 'About us - HopOn India',
            }
            // isVerified: false,
            // email: '',
            // name: '',
            // subject: 'Get In Touch',
            // message: '',
            // error: {
            //     email: '',
            //     name: '',
            //     subject: '',
            //     message: ''
            // }
        }
    }

    componentDidMount() {
        // if(this.props.match.params && this.props.match.params.subject){
        //      let str = this.props.match.params.subject;
        //      let subject = str.split('-').join(' ');
        //      this.setState({
        //         ...this.state,
        //         subject: subject
        //     })
        // }

    }

    // onCaptchaLoaded = () =>{
    //     console.log('Google Recaptcha Loaded');
    // }

    // When user clicks on recaptcha to set it
    // setRecaptcha = (response) => {
    //     if(response){
    //         this.setState({
    //             isVerified: true
    //         })
    //     }
    // }

    // Popup taost notification
    // showToast = (type, message) => {
    //     swal({
    //         title: message,
    //         text: ' ',
    //         icon: type,
    //         timer: 2000,
    //         buttons: false,
    //     })
    // }

    // Reseting the recaptcha
    // resetRecaptcha = (e) => {
    //     e.preventDefault();
    //     this.setState({
    //         isVerified: false
    //     }, () => {
    //         recaptchaInstance.reset();
    //     })
    // }

    // Handling the input values
    // inputHandler = (key, value) => {
    //     this.setState({
    //         [key]: value
    //     })
    // }

    // Submit the form
    // submitHandler = (event) => {
    //     event.stopPropagation();
    //     event.preventDefault();
    //     if(this.state.email && this.state.name && this.state.message && this.state.subject && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email))){
    //         this.setState({
    //             ...this.state,
    //             error: {
    //                 email: '',
    //                 name: '',
    //                 subject: '',
    //                 message: ''
    //             }
    //         }, () => {
    //             let fd = new FormData();
    //             fd.append('name', this.state.name);
    //             fd.append('email', this.state.email);
    //             fd.append('subject', this.state.subject);
    //             fd.append('body', this.state.message);
    //             this.props.dispatch(APIService('POST', '/contactus/contact-us', fd)).then((response) => {
    //                 if(response && response.data.status === 'success'){
    //                     this.setState({
    //                         name: '',
    //                         email: '',
    //                         subject: '',
    //                         message: ''
    //                     }, () => {
    //                         this.resetRecaptcha(event);
    //                         this.showToast('success', response.data.message)
    //                     })
    //                 }
    //                 else if(response.data.auth_failed === '1'){
    //                     this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
    //                         this.showToast('error', 'Invalid login. Please try again');
    //                         this.props.logoutUser();
    //                     })
    //                 }
    //                 else{
    //                     console.log('error')
    //                 }
    //             }).catch((e) => {
    //                 console.log(e)
    //             })
    //         })
    //     }
    //     else{
    //         this.setState({
    //             ...this.state,
    //             error: {
    //                 name: this.state.name ? '' : 'Name is required',
    //                 email: this.state.email ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email) ? '' : 'Enter a valid email address' : 'Email address is required',
    //                 subject: this.state.subject ? '' : 'Subject is required',
    //                 message: this.state.message ? '' : 'Message is required'
    //             }
    //         }, () => {
    //             console.log(event)
    //             this.resetRecaptcha(event)
    //         })
    //     }
    // }

    render() {
        if (this.props.match.params && this.props.match.params.subject) {
            window.scrollTo(0, 1250);
        }
        else {
            window.scrollTo(0, 0);
        }
        return (
            <Fragment>
                {/* Html goes here */}
                <MetaDecorator meta_info={this.state.meta_info} />
                <HeaderComponent active={'about'} />
                {/* banner section starts here */}
                <div className="banner_section inner_banner" style={{ backgroundColor: '#F2F2F2' }}>
                    <img src={avatar1} alt="Banner image" />
                </div>
                {/* banner section ends here */}
                <BuyNow />
                {/* Work section ends here */}
                <section className="page_section">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-12">
                                <h2 className="main_title">About Us</h2>
                            </div>
                            <div className="col-12 col-md-8 order-2 order-md-1">
                                <div className="about_mission">
                                    <p>We, at HopOn India by Desiwalks Tours Pvt. Ltd.,  use the power of modern cloud technology to enhance visitor experiences and tell stories about destinations like Museums and Monuments. We help you kick off your journey of digital transformation with our end-to-end affordable solutions. High-quality content, simple technology and user centric design are brought together to disseminate information and experiences.</p>
                                    <p>These experiences can be about any environment- museum, monument, a street art collection, a city landscape or any other. These can also be based on themes as varied as heritage, food, sports, art or living cultures etc. The idea is to tell authentic stories about our surroundings using simple technology and create immersive experiences.</p>
                                    <p>HopOn with us on the journey of digital transformation of your destination!</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 text-center order-md-2 order-1 about-img">
                                <img src={logo} alt="" className="work_img" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* work toure section ends here */}

                {/* contact form starts here */}
                {/* <section className="page_section contact_abt_section" > 
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-left text-md-center mb_30 mt-1 ">
                                <h2 className="main_title white_text">Have something <br/>to share?</h2>
                                <p className="white_text">Feedback from some  walk? An idea for a tour? Want to just reach out <br/>and say hi? Feel free to drop us a line!</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="page_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-12 col-md-10">
                                <div className="conatct_box">
                                    <form onSubmit={this.submitHandler}>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"><FontAwesomeIcon icon="user" /></span>
                                                    </div>
                                                    <input type="text" className="form-control" value={this.state.name} onChange={(e) => this.inputHandler('name', e.currentTarget.value)} placeholder="Name" />
                                                </div>
                                                {this.state.error.name ? (
                                                    <p style={{color:'red', fontSize:'13px', marginLeft:'5px', marginTop:'-8px'}}>{this.state.error.name}</p>
                                                ) : null}
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"><FontAwesomeIcon icon="envelope" /></span>
                                                    </div>
                                                    <input type="text" className="form-control" value={this.state.email} onChange={(e) => this.inputHandler('email', e.currentTarget.value)} placeholder="Email" />
                                                </div>
                                                {this.state.error.email ? (
                                                    <p style={{color:'red', fontSize:'13px', marginLeft:'5px', marginTop:'-8px'}}>{this.state.error.email}</p>
                                                ) : null}
                                            </div>
                                            <div className="col-12">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"><FontAwesomeIcon icon="pen" /></span>
                                                    </div>
                                                    <select className="form-control" value={this.state.subject} onChange={(e) => this.inputHandler('subject', e.currentTarget.value)}>
                                                        <option value="Get In Touch">Get In Touch</option>
                                                        <option value="I Need Customization">I Need Customization</option>
                                                        <option value="Request A Demo">Request A Demo</option>
                                                    </select>
                                                </div>
                                                {this.state.error.subject ? (
                                                    <p style={{color:'red', fontSize:'13px', marginLeft:'5px', marginTop:'-8px'}}>{this.state.error.subject}</p>
                                                ) : null}
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <textarea rows="2" className="form-control" style={{border:'1px solid silver'}} value={this.state.message} onChange={(e) => this.inputHandler('message', e.currentTarget.value)} placeholder="Your Message in details" />
                                                </div>
                                                {this.state.error.message ? (
                                                    <p style={{color:'red', fontSize:'13px', marginLeft:'5px', marginTop:'-8px'}}>{this.state.error.message}</p>
                                                ) : null}
                                            </div>
                                            <div className="col-12 col-md-5 col-sm-12">
                                                <div className="form-group">
                                                    <Recaptcha
                                                        sitekey="6LekxvsZAAAAAIgSo4cjYW8VY-A0hiiJnhP5JG9f"
                                                        render="explicit"
                                                        verifyCallback={this.setRecaptcha}
                                                        onloadCallback={this.onCaptchaLoaded}
                                                        ref={e => recaptchaInstance = e}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 text-center">
                                                <input type="submit" className="btn btn-sm orange_btn" disabled={!this.state.isVerified} value="SEND"/>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>
                </section> */}
                {/* contact form section ends here */}


                <FooterComponent />

            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(About);
