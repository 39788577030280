import React, { Component } from 'react';
import { connect } from 'react-redux';
import { APIService } from '../../../../Config/actions/apiAction';
import swal from 'sweetalert';
import { Fragment } from 'react';
import GplayImg from '../../../../images/gplay.png';
import appleplayImg from '../../../../images/aplestor.png';
import HeaderComponent from '../../../Common/components/header';
import FooterComponent from '../../../Common/components/footer';

class PackageFailurePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: '',
            data: ''
        }
    }

    route = (url) => {
        sessionStorage.setItem('homeScroll', window.pageYOffset);
        this.props.history.push(url)
    }

    showToast = (type, message) => {
        swal({
            text: ' ',
            title: message,
            icon: type,
            timer: 2000,
            buttons: false,
        })
    }

    componentDidMount() {
        this.setState({
            token: this.props.match.params.token
        }, () => {
            let fd = new FormData();
            fd.append('txn_id', this.state.token);
            this.props.dispatch(APIService('POST', '/home/check-transaction', fd)).then((data) => {
                if (data && data.data.status === 'success') {
                    this.setState({
                        data: data.data.data
                    })
                }
                else if (data && data.data.auth_failed === '1') {

                }
                else if (data && data.data.status === 'error') { this.route('/not-found') }
            }).catch((e) => {
                // console.log(e)
            })
        })
    }

    render() {
        return (
            <Fragment>
                <HeaderComponent />
                <section className="page_section success_page" >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h1 className="mb-5 mt-5"><span className="orange_text text-capitalize">
                                    Hello {this.state.data.user},</span> <br/>failed to buy the package <br/>{this.state.data.product_name}! <br/>Please try again! </h1>
                                {
                                    (this.state.data.buy_from == 'pwa') ? (
                                        <button className="btn btn-sm orange_btn mb-30" onClick={() => window.open(this.state.data.back_url, "_self")}> Back </button>
                                    ) : null
                                }
                                <h1 className="green_text mb-5"><b>Download the App now!</b></h1>
                                <div className="d-inline-block mb-5 mt-3">
                                    <a href="https://play.google.com/store/apps/details?id=com.hoponindia&hl=en_IN&gl=US"><img src={GplayImg} className="mr-3 mb-3" /></a>
                                    <a href="https://apps.apple.com/us/app/hopon-india/id1205385874?ls=1"><img src={appleplayImg} className="mr-3 mb-3" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <FooterComponent />
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(PackageFailurePage);